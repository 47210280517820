import React from 'react';
import './App.css';
import Button from '@mui/material/Button';

const TextCarousel = () => {
  const words = ['شاعر', ' कवि ', "Shaa'ir", 'ਕਵੀ', 'கவிஞர்'];
  const [currentWordIndex, setCurrentWordIndex] = React.useState(0);

  React.useEffect(() => {
    const updateWord = () => {
      setCurrentWordIndex((prevIndex) => (prevIndex + 1) % words.length);
    };

    const intervalId = setInterval(updateWord, 2000);

    return () => clearInterval(intervalId);
  }, []);

  return <div className="element">{words[currentWordIndex]}</div>;
};

function App() {
  const handleButtonClick = () => {
    // Redirect to shaair.myshopify.com
    window.location.href = 'https://shaair.myshopify.com';
  };

  return (
    <div className="App">
      <header className="App-header">
        <div className="frame">
          <div className="center">
            <div className="carousel">
              <div className="change_outer">
                <div className="change_inner">
                  <TextCarousel />
                </div>
              </div>
            </div>
            <Button
              variant="outlined"
              onClick={handleButtonClick}
              style={{
                width: '180px',
                color: 'black',  // Text color
                border: '1px solid black',  // Border color
              }}
            >
              Enter Now
            </Button>
          </div>
        </div>
      </header>
    </div>
  );
}

export default App;



